<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20" type="flex" align="bottom" justify="space-between">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <el-input v-model="creator_id" placeholder="请输入铸造人ID" size="normal"></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input
                v-model="creator_nickname"
                placeholder="请输入铸造人昵称"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input
                v-model="creator_mobile"
                placeholder="请输入铸造人手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="0"
              ><el-input
                v-model="creator_email"
                placeholder="请输入铸造人邮箱"
                size="normal"
              ></el-input
            ></el-col>
            <!--  -->
          </el-row>

          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="6" :offset="0">
              <el-input v-model="name" placeholder="请输入作品名称" size="normal"></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input v-model="txid" placeholder="请输入NFT txid" size="normal"></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-date-picker
                v-model="create_at_start"
                type="datetime"
                size="normal"
                placeholder="选择铸造开始时间"
                style="width: 100%"
                value-format="timestamp"
              />
            </el-col>
            <el-col :span="6" :offset="0">
              <el-date-picker
                v-model="create_at_end"
                type="datetime"
                size="normal"
                placeholder="选择铸造结束时间"
                style="width: 100%"
                value-format="timestamp"
              />
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="6" :offset="0">
              <el-select v-model="creator_audit_status" style="width: 100%">
                <el-option label="全部铸造者认证状态" value="all"> </el-option>
                <el-option label="已认证" value="certified"> </el-option>
                <el-option label="未认证" value="not_certified"> </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-select v-model="platform" style="width: 100%">
                <el-option label="全平台" value="all"> </el-option>
                <el-option label="仅恒镜" value="forever_realm_only"> </el-option>
                <el-option label="其他平台" value="other_platform"> </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-select v-model="audit_status" style="width: 100%">
                <el-option label="全部审核状态" value="all"> </el-option>
                <el-option label="待审核" value="prepare"> </el-option>
                <el-option label="已审核" value="pass"> </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-select v-model="display_status" style="width: 100%">
                <el-option label="全部冻结状态" value="all"> </el-option>
                <el-option label="公开" :value="1"> </el-option>
                <el-option label="冻结" :value="2"> </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0" style="text-align: right">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
        <el-col :span="2" :offset="2" style="text-align: right">
          <el-button type="primary" size="default" @click="castVisible = true">
            铸造
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="NFT合集名称" prop="name"> </el-table-column>
      <el-table-column label="预览图" prop="pics" width="320px">
        <template slot-scope="scope">
          <el-image
            v-for="(pic, idx) in scope.row.pics"
            :key="idx"
            :src="pic"
            fit="fill"
            :lazy="true"
            :preview-src-list="scope.row.pics"
            style="max-width: 140px; max-height: 140px; margin-right: 8px"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column label="铸造者信息" prop="creator_info"> </el-table-column>
      <el-table-column label="铸造者信息" prop="id" width="160px">
        <template slot-scope="scope">
          <CopyTxid :txid="scope.row.id" />
        </template>
      </el-table-column>
      <!-- <el-table-column label="铸造者国家" prop="creator_country"></el-table-column> -->
      <el-table-column label="NFT总数" prop="total"></el-table-column>
      <el-table-column label="在售数量" prop="on_shelf_count"></el-table-column>
      <el-table-column label="冻结状态" prop="status_desc">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="handleClickStatus(scope.row)">
            {{ scope.row.status_desc }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="铸造者认证状态" prop="creator_audit_status"></el-table-column>

      <el-table-column label="铸造时间" prop="created_at" width="100px">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="audit_status_str"> </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleQueryNFTs(scope.row)"
            style="margin: 8px 0"
          >
            查看NFT合集信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="合集状态变更" :visible.sync="visible" width="30%" @close="visible = false">
      <el-select v-model="selectedStatus">
        <el-option label="公开" :value="1"> </el-option>
        <el-option label="冻结" :value="2"> </el-option>
      </el-select>

      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="handleChangeStatus">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="铸造"
      :visible.sync="castVisible"
      width="30%"
      @close="closeCast"
      :destroy-on-close="true"
    >
      <el-form
        :inline="true"
        :model="queryForm"
        label-position="top"
        class="cast-form"
        ref="form"
        :rules="rules"
      >
        <el-form-item item label="铸造用户" prop="owner_ucid">
          <el-input
            v-model="queryForm.owner_ucid"
            placeholder="请输入用户UCID"
            :clearable="true"
            @clear="showBalance = false"
          >
            <el-button slot="append" icon="el-icon-search" @click="searchUcid"></el-button
          ></el-input>
          <div v-if="showBalance">
            <div class="label-title">{{ userBalance.username }}</div>
            <b>用户0号钱包</b>&nbsp;
            <span class="label-desc">请保证该钱包有足够的BSV用于铸造</span>
            <div class="tip-between">
              <div><CopyText :text="userBalance.address" /></div>
              <div>{{ `余额: ${userBalance.amount}BSV` }}</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item item label="铸造类型" prop="work_type">
          <el-select v-model="queryForm.work_type" placeholder="请选择">
            <el-option label="图片" value="1"> </el-option>
            <el-option label="音乐" value="2" disabled> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="藏品图片" prop="pic">
          <div class="label-desc">
            藏品仅展示压缩后的图片。只支持 JPEG / PNG / JPG等主流图片格式，图片限制 20M 以内。
          </div>
          <!-- <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :on-remove="handleRemove"
            :file-list="picList"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload> -->
          <el-upload
            action=""
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :on-remove="handleRemove"
            :file-list="picList"
            :limit="1"
            :http-request="uploadImage"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item item label="藏品名称" prop="name">
          <el-input
            v-model="queryForm.name"
            placeholder="示例 蒙娜丽莎,清明上河图"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item item label="发行数量(不做限制)" prop="count">
          <el-input-number
            v-model="queryForm.count"
            placeholder=""
            :min="1"
            @change="setCountFee"
          ></el-input-number>
        </el-form-item>
        <el-form-item item label="授权范围" prop="authority_type">
          <el-select v-model="queryForm.authority_type" placeholder="请选择">
            <el-option label="个人授权" value="personal"> </el-option>
            <el-option label="商业授权" value="business"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item item label="上架范围" prop="on_shelf_area">
          <el-select v-model="queryForm.on_shelf_area" placeholder="请选择">
            <el-option label="中国大陆上架" :value="0"> </el-option>
            <el-option label="海外上架" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item item label="著作权许可费率(0~3%)" prop="copyright_rate">
          <el-input-number
            v-model="queryForm.copyright_rate"
            placeholder=""
            :max="3"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item item label="藏品描述" prop="desc">
          <el-input
            type="textarea"
            v-model="queryForm.desc"
            placeholder="完善藏品描述将提高藏品出售几率"
            maxlength="1000"
            show-word-limit
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-footer">
          <span>预计铸造费用: {{ countFee }} BSV</span>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <div class="label-footer">预估铸造费存在偏差,若铸造失败则可多转BSV</div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getNFTList, setNFTStatus, getUserBalance, castNFT } from "@/api/nft.js";
import dayjs from "dayjs";
import CopyTxid from "@/components/CopyTxid.vue";
import CopyText from "@/components/CopyText.vue";
import { uploadImage } from "@/utils/helper.js";

export default {
  components: {
    CopyTxid,
    CopyText,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      visible: false,
      selectedStatus: 1,
      editNFT: null,

      // 查询参数-第一行
      creator_id: "",
      creator_mobile: "",
      creator_email: "",
      creator_nickname: "",
      // 查询参数-第二行
      name: "",
      txid: "",
      create_at_start: "",
      create_at_end: "",
      // 查询参数-第三行
      creator_audit_status: "all",
      platform: "forever_realm_only",
      audit_status: "all",
      display_status: "all",

      castVisible: false,
      queryForm: {},
      showBalance: false,
      countFee: 0,
      userBalance: {
        address: "",
        amount: "",
        username: "",
      },
      rules: {
        owner_ucid: [{ required: true, message: "请填写ucid" }],
        work_type: [{ required: true, message: "请选择铸造类型" }],
        pic: [{ required: true, message: "请上图片" }],
        name: [{ required: true, message: "请输入藏品名称", trigger: "blur" }],
        count: [{ required: true, message: "请输入发行数量", trigger: "blur" }],
        authority_type: [{ required: true, message: "请选择授权范围" }],
        on_shelf_area: [{ required: true, message: "请选择上架范围" }],
        copyright_rate: [{ required: true, message: "请输入著作权许可费率" }],
        desc: [{ required: true, message: "请输入藏品描述" }],
      },
      picList: [],
    };
  },
  filters: {
    formatDate: function(value) {
      if (!value) {
        return "";
      }
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);

    const creator_id = search.get("creator_id");
    if (creator_id) {
      this.creator_id = creator_id;
    }

    // this.fetchNFTList();
  },
  computed: {},
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.creator_id && (params.creator_id = this.creator_id);
      this.creator_mobile && (params.creator_mobile = this.creator_mobile);
      this.creator_email && (params.creator_email = this.creator_email);
      this.creator_nickname && (params.creator_nickname = this.creator_nickname);

      this.name && (params.name = this.name);
      this.txid && (params.txid = this.txid);
      this.create_at_start && (params.create_at_start = Math.floor(this.create_at_start / 1000));
      this.create_at_end && (params.create_at_end = Math.floor(this.create_at_end / 1000));

      this.creator_audit_status !== "all" &&
        (params.creator_audit_status = this.creator_audit_status);
      this.platform && (params.platform = this.platform);
      this.audit_status !== "all" && (params.audit_status = this.audit_status);
      this.display_status !== "all" && (params.display_status = this.display_status);

      return params;
    },
    async fetchNFTList() {
      try {
        const params = this.getSearchParams();
        const start = params.create_at_start;
        const end = params.create_at_end;
        if (start && end && start > end) {
          this.$message.error("开始时间不能大于结束时间");
          return;
        }

        const { data } = await getNFTList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    handleQueryNFTs(row) {
      this.$router.push("/nft_management/nft_items?id=" + row.id);
    },

    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchNFTList();
    },

    handleClickStatus(row) {
      this.visible = true;
      this.selectedStatus = +row.status;
      this.editNFT = { ...row };
    },
    handleUploadSuccess(res, file) {
      this.queryForm.pic = res.data;
      this.$refs.form.clearValidate("pic");
    },
    handleRemove(file, fileList) {
      this.queryForm.pic = "";
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.handleCast();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    uploadImage(item) {
      return uploadImage(item.file).then((url) => {
        this.queryForm.pic = url;
      });
    },
    async handleCast() {
      const params = { ...this.queryForm };
      params.copyright_rate = params.copyright_rate.toString();
      // console.log(params);
      try {
        const { data } = await castNFT(params);
        this.closeCast();
        this.$message.success("铸造完成，请耐心等待");
      } catch (error) {}
    },
    closeCast() {
      this.castVisible = false;
      this.showBalance = false;
      this.countFee = 0;
      this.queryForm = {};
    },

    async handleChangeStatus() {
      try {
        const nft_id = this.editNFT.id;
        const status = this.selectedStatus;
        const { data } = await setNFTStatus(nft_id, status);
        console.log(data);
        this.$message.success("修改成功");
        this.fetchNFTList();
        this.visible = false;
        this.editNFT = null;
      } catch (error) {
        const msg = error?.data?.msg;

        if (msg) {
          this.$message.error(msg);
        }
      }
    },

    resetQuery() {
      this.page = 1;
      this.pageSize = 20;

      this.creator_nickname = "";
      this.creator_id = "";
      this.creator_mobile = "";
      this.creator_email = "";

      this.name = "";
      this.txid = "";
      this.create_at_start = "";
      this.create_at_end = "";
      // 查询参数-第三行
      this.creator_audit_status = "all";
      this.platform = "forever_realm_only";
      this.audit_status = "all";
      this.display_status = "all";
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchNFTList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchNFTList();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },
    beforeAvatarUpload(rawFile) {
      if (rawFile.size / 1024 / 1024 > 20) {
        this.$message.error("文件限制在 20MB!");
        return false;
      }
      return true;
    },
    async searchUcid() {
      // console.log(this.queryForm.ucid);
      try {
        const { data } = await getUserBalance({ owner_ucid: this.queryForm.owner_ucid });
        this.userBalance = data.data;
        this.showBalance = true;
        // console.log(data, this.userBalance);
      } catch (error) {
        const msg = error?.data?.msg;
        if (msg) {
          this.$message.error(msg);
        }
      }
    },
    setCountFee() {
      if (this.queryForm.count) {
        this.countFee = (0.00011 * this.queryForm.count).toFixed(5);
      } else {
        this.countFee = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cast-form {
  width: 100%;

  .el-form-item {
    width: 100%;
  }
  .label-title {
    font-size: smaller;
  }
  .label-desc {
    width: 100%;
    font-size: smaller;
    color: lightgray;
  }
  .tip-between {
    font-size: smaller;
    display: flex;
    justify-content: space-between;
  }
  .form-footer {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 12px;
    }
    .label-footer {
      color: #7f4500;
      font-size: smaller;
    }
  }
}
.common-image-uploader {
  .common-image {
    width: 200px;
    height: 200px;
    display: block;
  }
  .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }
  .el-upload:hover {
    border-color: var(--el-color-primary);
  }

  .el-icon.common-image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }
}
</style>
